@use '@/styles/utils/mixins.scss' as *;

.float-select-label {
  position: relative;
  font-family: 'Fakt Pro', sans-serif;

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.75rem;
    top: 1rem;
    z-index: 99;
    font-size: 1rem;
    background: $transparent;
    padding: 0;
    color: $neutral07;

    &.has-value {
      color: $neutral07;
      font-size: 0.75rem;
    }
  }

  .float-select {
    .ant-select-selector {
      height: auto;
    }

    .anticon.anticon-plus {
      color: $dark;
      pointer-events: none !important;
    }

    .ant-select-selector .ant-select-selection-item {
      height: auto !important;
      line-height: 1.5;
      font-family: 'Fakt Pro', sans-serif;
      font-size: 1rem;
      margin-block: 0;
      display: flex;
      align-items: center;
      max-width: 100%;
      margin-inline-end: 0;
      padding: 1rem 0.75rem;
    }
  }

  .ant-select .ant-select-arrow > *:not(:last-child) {
    margin-inline-end: 0;
  }
  .ant-select .ant-select-selection-search-mirror {
    height: auto !important;
    line-height: 1 !important;
  }
  .ant-select .ant-select-arrow {
    top: auto !important;
    bottom: 1.25rem;
  }
  .ant-select .ant-select-clear {
    top: auto !important;
    bottom: 1.25rem;
  }
  .ant-select-selector .ant-select-selection-search-input {
    font-family: 'Fakt Pro', sans-serif;
    font-size: 1rem;
  }
  .ant-form-item .ant-select-selection-search-input {
    height: auto;
  }
  .ant-select-selector .ant-select-selection-search-input {
    height: auto !important;
    font-family: 'Fakt Pro', sans-serif;
    font-size: 1rem;
    line-height: 1.2 !important;
  }
  .ant-select-selector {
    font-size: 1rem;
    padding: 1rem 0.75rem !important;
    line-height: 1.2 !important;
    transition: all 0.2s ease-in-out;
    &:after {
      line-height: 0 !important;
    }
  }

  .ant-select-selector .ant-select-selection-search {
    top: 1rem !important;
    bottom: 1rem !important;
  }
  .ant-select-selector {
    .ant-select-selection-overflow {
      .ant-select-selection-item {
        padding: 0.25rem 0.5rem !important;
      }
    }
  }
  .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1.2 !important;
  }

  &.has-value {
    .ant-select-selector {
      padding-top: 2rem !important;
    }
    .ant-select-selector .ant-select-selection-search {
      top: 2rem !important;
    }
  }
  .ant-select-selector .ant-select-selection-item {
    line-height: 1 !important;
    padding-top: 0.25rem !important;
  }

  .ant-form-item-feedback-icon {
    display: none;
  }

  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    .ant-select-selection-search {
      top: 0rem !important;
      margin-inline-start: 0;
    }
  }
}
