@use '@/styles/utils/mixins.scss' as *;

.float-label-textarea {
  position: relative;
  font-family: 'Fakt Pro', sans-serif;

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.75rem;
    top: 1rem;
    z-index: 99;
    font-size: 1rem;
    background: $transparent;
    padding: 0;
    color: $neutral07;
    transition: all 0.2s ease-in-out;

    &.has-value {
      color: $neutral07;
      font-size: 0.75rem;
    }
  }

  .float-input {
    padding: 1rem 0.75rem;
    font-size: 1rem !important;
    line-height: 1.2;
    font-family: 'Fakt Pro', sans-serif;
    transition: all 0.2s ease-in-out;

    &:focus {
      border-color: $primary;
      outline: none;
    }

    textarea.ant-input {
      padding: 1.2rem 0.75rem;
      font-family: 'Fakt Pro', sans-serif;
      font-size: 1rem !important;
      color: $dark !important;
    }

    resize: vertical;
  }

  &.has-value {
    .float-input {
      padding-top: 2rem;
      textarea.ant-input {
        padding-top: 0;
      }
    }
  }

  .ant-form-item-feedback-icon {
    display: none;
  }
}
